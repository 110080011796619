define('ember-bulma/components/bulma-tag', ['exports', 'ember-bulma/templates/components/bulma-tag', 'ember-decorators/object', 'ember-bulma/constants'], function (exports, _bulmaTag, _object, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _object.computed)('remove'), (_obj = {
    layout: _bulmaTag.default,
    tagName: 'span',
    classNames: ['tag'],
    classNameBindings: [].concat(_constants._helpers, _constants._colorBindings, _constants._responsiveHelpers),

    /**
      Explicitly define remove to override the internal method
       @method remove
      @public
    */
    remove: null,

    _canDelete: function _canDelete(remove) {
      return !!remove;
    },

    actions: {
      /**
        Handle remove, return instance of component for mutablity
         @method close
        @returns Component
        @public
      */
      remove: function remove() {
        this.sendAction('remove', this.get('tag'), this);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_canDelete', [_dec], Object.getOwnPropertyDescriptor(_obj, '_canDelete'), _obj)), _obj)));
});