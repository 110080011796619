define('ember-bulma/components/bulma-nav-right', ['exports', 'ember-bulma/templates/components/bulma-nav-right', 'ember-bulma/constants'], function (exports, _bulmaNavRight, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaNavRight.default,
    classNames: ['nav-right'],
    classNameBindings: ['isMenu:nav-menu'].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});