define('ember-bulma/components/bulma-input', ['exports', 'ember-bulma/templates/components/bulma-input', 'ember-bulma/utils', 'ember-bulma/constants'], function (exports, _bulmaInput, _utils, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    layout: _bulmaInput.default,
    tagName: 'input',
    classNames: ['input'],
    classNameBindings: ['isSmall:is-small', 'isMedium:is-medium', 'isLarge:is-large'].concat(_constants._helpers),
    // Bindings are not comprehensive. More complex implementations should use a native element with classes applied
    attributeBindings: ['type', 'value', 'name', 'accept', 'isDisabled:disabled', 'disabled', 'autocomplete', 'autofocus', 'dirname', 'list', 'readonly', 'size', 'required', 'multiple', 'maxlength', 'pattern', 'min', 'step', 'placeholder', 'onkeydown', 'onkeyup', 'onkeypress', 'oninput', 'onchange', 'onblur', 'onfocus'],

    /**
      * Default class name binding
      *
      * @property isNormal
      * @public
      * @type Bool
      * @default true
      */
    isNormal: true,

    init: function init() {
      this._super.apply(this, arguments);

      // Make user aware of deprecations for this Component
      (0, _utils.deprecations)([{ name: 'control', value: get(this, 'control') }, { name: 'isLoading', value: get(this, 'isLoading') }, { name: 'isTextarea', value: get(this, 'isTextarea') }, { name: 'key-up', value: get(this, 'key-up') }]);
    }
  });
});