define('ember-bulma/components/bulma-header-menu', ['exports', 'ember-bulma/templates/components/bulma-header-menu', 'ember-bulma/constants'], function (exports, _bulmaHeaderMenu, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      A = Ember.A;
  exports.default = Component.extend({
    layout: _bulmaHeaderMenu.default,
    classNames: ['header-menu'],
    classNameBindings: ['isLeft:header-left', 'isRight:header-right', 'left:header-left', 'right:header-right'].concat(_constants._helpers, _constants._responsiveHelpers),
    /**
      Array of links for this menu
       @property links
      @returns Array
      @public
    */
    links: A([])
  });
});