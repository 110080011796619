define("ember-intl/-private/utils/hydrate", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-intl/translations"], function (_exports, _slicedToArray2, _translations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hydrate;

  /**
   * @private
   * @hide
   */
  function hydrate(service) {
    _translations.default.forEach(function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          locale = _ref2[0],
          translations = _ref2[1];

      service.addTranslations(locale, translations);
    });
  }
});