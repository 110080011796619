define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-se", {
    "actions": {
      "choose_language": "Choose a language:",
      "download": "Download Resume",
      "go_to_github_repo": "Go to Github Repository",
      "view_site": "View Site"
    },
    "labels": {
      "file_pdf": "pdf file icon"
    },
    "locales": {
      "en-se": "English (International)",
      "pt-br": "Português (Brasil)"
    },
    "main": {
      "education": "Education",
      "interests": "Interests",
      "languages": "Languages",
      "name": "Name",
      "preview": "Preview",
      "projects": "Projects",
      "resume": "Resume",
      "skills": "Skills",
      "work": "Work"
    },
    "thesis": "Thesis",
    "time": {
      "present": "present"
    }
  }], ["pt-br", {
    "actions": {
      "choose_language": "Escolha o idioma:",
      "download": "Abaixe o Resume",
      "go_to_github_repo": "Abra o Github Repositório",
      "view_site": "Visite o Site"
    },
    "labels": {
      "file_pdf": "pdf arquivo ícone"
    },
    "locales": {
      "en-se": "English (International)",
      "pt-br": "Português (Brasil)"
    },
    "main": {
      "education": "Educação",
      "interests": "Interesses",
      "languages": "Idiomas",
      "name": "Nome",
      "preview": "Prévia",
      "projects": "Projetos",
      "resume": "Resume",
      "skills": "Habilidades",
      "work": "Experiência Profissional"
    },
    "thesis": "Tese",
    "time": {
      "present": "presente"
    }
  }]];
  _exports.default = _default;
});