define('ember-bulma/components/bulma-menu-list', ['exports', 'ember-bulma/templates/components/bulma-menu-list'], function (exports, _bulmaMenuList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaMenuList.default,
    tagName: ''
  });
});