define('ember-bulma/components/bulma-footer', ['exports', 'ember-bulma/templates/components/bulma-footer', 'ember-bulma/constants'], function (exports, _bulmaFooter, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaFooter.default,
    tagName: 'footer',
    classNames: ['footer'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});