define('ember-bulma/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray,
      Logger = Ember.Logger;


  /**
    Convert an array to space delimited String
  
    @method makeString
    @returns String
    @public
  */
  var makeString = exports.makeString = function makeString(arr) {
    var unique = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    return isArray(arr) ? (unique ? arr.uniq() : arr).toString().replace(/,/g, ' ') : arr;
  };

  /**
    Camel case a string
  
    @method camelCase
    @returns String
    @public
  */
  var camelCase = exports.camelCase = function camelCase(str) {
    var arr = str.split('-');
    if (arr.length > 1) {
      return arr.map(camelCase).join('');
    }
    if (/[a-z]/.test(str)) {
      var _arr = str.split('');
      return ('' + _arr[0].toUpperCase() + _arr.slice(1, _arr.length)).replace(/,/g, '');
    } else {
      return str;
    }
  };

  /**
    * Deprecation messages util
    *
    * @method deprecations
    * @private
    */
  var deprecations = exports.deprecations = function deprecations(items) {
    var msg = {
      control: 'control=true is no longer supported. Please wrap the component in a bulma-control component, or with a container using the class .control',
      isTextarea: 'isTextarea=true is no longer supported. Please use bulma-textarea component instead',
      isLoading: 'isLoading=true is no longer supported. Please wrap the component in a bulma-control container with the isLoading flag, or any container using the classes .control and .is-loading',
      'key-up': 'key-up is no longer supported as an event. Please use oninput or some other native event handler instead.'
    };

    items.forEach(function (item) {
      if (item.value && msg.hasOwnProperty(item.name)) {
        Logger.warn(msg[item.name]);
      }
    });
  };
});