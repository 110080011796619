define('ember-bulma/components/bulma-columns', ['exports', 'ember-bulma/templates/components/bulma-columns', 'ember-bulma/constants'], function (exports, _bulmaColumns, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaColumns.default,
    classNames: ['columns'],
    classNameBindings: ['desktop:is-desktop', 'isDesktop:is-desktop', 'mobile:is-mobile', 'isMobile:is-mobile', 'isMultiline:is-multiline', 'isGapless:is-gapless', 'isVcentered:is-vcentered'].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});