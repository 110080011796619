define('ember-bulma/components/bulma-modal', ['exports', 'ember-bulma/templates/components/bulma-modal', 'ember-bulma/constants'], function (exports, _bulmaModal, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$run = Ember.run,
      schedule = _Ember$run.schedule,
      bind = _Ember$run.bind,
      get = Ember.get,
      _$ = Ember.$;
  exports.default = Component.extend({
    layout: _bulmaModal.default,
    classNames: ['modal'],
    classNameBindings: ['show:is-active'].concat(_constants._helpers),
    /**
      * Include background container
      *
      * @property withBackground
      * @type Boolean
      * @default true
      * @public
      */
    withBackground: true,

    /**
      * Wraps content with .modal-container which also centers content
      *
      * @property isCentered
      * @type Boolean
      * @default true
      * @public
      */
    isCentered: true,

    /**
      * Include close button
      *
      * @property withClose
      * @type Boolean
      * @default true
      * @public
      */
    withClose: true,

    /**
      * Function to apply when closing modal
      *
      * @property onclose
      * @type Function
      * @default null
      * @public
      */
    onclose: null,

    /**
      * Execute on close when escape is pressed
      *
      * @method escapeHandler
      * @private
    */
    escapeHandler: function escapeHandler(e) {
      if (get(this, 'onclose') && get(e, 'keyCode') === 27) {
        get(this, 'onclose')();
      }
    },


    /**
      * Attach event handlers
       * @method attachKeyUpHandlers
      * @private
      */
    attachKeyUpHandlers: function attachKeyUpHandlers() {
      var _this = this;

      schedule('afterRender', function () {
        _$(document).on('keyup.ember-bulma-modal', bind(_this, _this.escapeHandler));
      });
    },


    /**
      * Remove event handlers
       * @method detatchKeyUpHandlers
      * @private
      */
    detachKeyUpHandlers: function detachKeyUpHandlers() {
      _$(document).off('keyup.ember-bulma-modal');
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.attachKeyUpHandlers();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.detachKeyUpHandlers();
    }
  });
});