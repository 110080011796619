define('ember-bulma/components/bulma-control', ['exports', 'ember-bulma/templates/components/bulma-control', 'ember-bulma/constants'], function (exports, _bulmaControl, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaControl.default,
    tagName: 'p',
    classNames: ['control'],
    classNameBindings: ['isLoading:is-loading', 'isDisabled:is-disabled', 'disabled:is-disabled', 'loading:is-loading', 'hasAddons:has-addons', 'hasAddonsCentered:has-addons-centered', 'hasAddonsRight:has-addons-right', 'isGroup:is-group', 'isExpanded:is-expanded', 'isHorizontal:is-horizontal'].concat(_constants._helpers)
  });
});