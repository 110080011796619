define('ember-bulma/components/bulma-tabs', ['exports', 'ember-bulma/templates/components/bulma-tabs', 'ember-decorators/object', 'ember-bulma/constants'], function (exports, _bulmaTabs, _object, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      isEqual = Ember.isEqual,
      set = Ember.set,
      get = Ember.get,
      getOwner = Ember.getOwner,
      emberArray = Ember.A;
  exports.default = Component.extend((_dec = (0, _object.computed)('tabs.[]', '_containerRouter.currentRouteName'), _dec2 = (0, _object.computed)(), (_obj = {
    layout: _bulmaTabs.default,
    classNames: 'tabs',
    classNameBindings: ['isRight:is-right', 'isFullwidth:is-fullwidth', 'isBoxed:is-boxed', 'boxed:is-boxed', 'centered:is-centered', 'isCentered:is-centered', 'isToggle:is-toggle', 'toggle:is-toggle'].concat(_constants._helpers, _constants._responsiveHelpers),

    /**
      If used inline, consumer will provide an array of tabs
       @property tabs
      @returns Array
      @public
    */
    tabs: emberArray([]),

    _processedTabs: function _processedTabs(tabs, routeName) {
      // Compare the provided route to the current route
      if (tabs) {
        return tabs.map(function (tab) {
          set(tab, 'isActive', isEqual(get(tab, 'route'), routeName));
          return tab;
        });
      } else {
        return [];
      }
    },
    _containerRouter: function _containerRouter() {
      return getOwner(this).lookup('router:main');
    },

    actions: {
      /**
        Route tab to specified path and set as active, set toggle other active tabs
         @method route
        @public
      */
      routeTab: function routeTab(tab, tabs) {
        // De-activate all tabs
        emberArray(tabs).setEach('isActive', false);

        this.get('_containerRouter').transitionTo(get(tab, 'route')).then(function () {
          // Activate this tab using Ember.set (works with POJO or Ember Object)
          set(tab, 'isActive', true);
        });
      },


      /**
        Transition to new route and add is-active
         @method handleRouting
        @public
      */
      _handleRouting: function _handleRouting(tab) {
        this.send('routeTab', tab, this.get('_processedTabs'));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_processedTabs', [_dec], Object.getOwnPropertyDescriptor(_obj, '_processedTabs'), _obj), _applyDecoratedDescriptor(_obj, '_containerRouter', [_dec2], Object.getOwnPropertyDescriptor(_obj, '_containerRouter'), _obj)), _obj)));
});