define('ember-bulma/components/bulma-hero-content', ['exports', 'ember-bulma/templates/components/bulma-hero-content', 'ember-decorators/object/computed', 'ember-bulma/constants'], function (exports, _bulmaHeroContent, _computed, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _init;

  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _computed.alias)('useContainer'), (_obj = {
    layout: _bulmaHeroContent.default,
    classNames: ['hero-body'],
    classNameBindings: ['full:is-fullwidth'].concat(_constants._helpers, _constants._responsiveHelpers),

    /**
      Signal if is-fluid should be added to the outer container
       @property fluidContainer
      @public
    */
    fluidContainer: false,

    /**
      Signal if container wrapper should be used
       @property useContainer
      @returns Bool
      @public
    */
    useContainer: true,

    container: false
  }, (_applyDecoratedDescriptor(_obj, 'container', [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, 'container'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));
});