define('ember-bulma/components/bulma-nav-toggle', ['exports', 'ember-bulma/templates/components/bulma-nav-toggle', 'ember-bulma/constants'], function (exports, _bulmaNavToggle, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaNavToggle.default,
    tagName: 'span',
    classNames: ['nav-toggle'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers),
    /**
      Handle click action on mousedown, return instance of component for mutablity
       @method mouseDown
      @returns Component
      @public
    */
    mouseDown: function mouseDown() {
      var action = this.get('onmousedown');
      if (action) {
        action(this);
      }
    },

    actions: {
      click: function click() {
        this.mouseDown();
      }
    }
  });
});