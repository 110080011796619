define("ember-intl/-private/helpers/-format-base", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  var AbstractHelper = Ember.Helper.extend({
    intl: null,
    init: function init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }

      this._super();

      this.intl = Ember.getOwner(this).lookup('service:intl');
      this.intl.on('localeChanged', this, 'recompute');
    },
    format: function format() {
      throw new Error('not implemented');
    },
    compute: function compute(_ref, options) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          value = _ref2[0];

      if (Ember.isEmpty(value)) {
        var _options$allowEmpty;

        if ((_options$allowEmpty = options.allowEmpty) !== null && _options$allowEmpty !== void 0 ? _options$allowEmpty : this.allowEmpty) {
          return;
        }

        if (typeof value === 'undefined') {
          throw new Error("".concat(this, " helper requires value attribute."));
        }
      }

      return this.format(value, options);
    },
    willDestroy: function willDestroy() {
      this._super();

      this.intl.off('localeChanged', this, 'recompute');
    }
  });
  var _default = AbstractHelper;
  _exports.default = _default;
});