define('ember-bulma/components/bulma-radio', ['exports', 'ember-bulma/components/bulma-input', 'ember-bulma/templates/components/bulma-radio'], function (exports, _bulmaInput, _bulmaRadio) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = _bulmaInput.default.extend({
    layout: _bulmaRadio.default,

    classNames: ['radio'],
    type: 'radio',

    // Bindings are not comprehensive. More complex implementations should use a native element with classes applied
    classNameBindings: ['checked'],

    init: function init() {
      this._super.apply(this, arguments);

      var defaultAttrBindings = get(this, 'attributeBindings').slice();

      defaultAttrBindings.push('checked');

      set(this, 'attributeBindings', defaultAttrBindings);

      // classNames reset
      // Remove the inherited `input` class name (as it breaks the styling)
      set(this, 'classNames', get(this, 'classNames').filter(function (name) {
        return name !== 'input';
      }));
    }
  });
});