define('ember-bulma/components/bulma-column', ['exports', 'ember-bulma/templates/components/bulma-column', 'ember-bulma/constants'], function (exports, _bulmaColumn, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;
  exports.default = Component.extend({
    layout: _bulmaColumn.default,
    classNames: ['column'],
    // REVIEW can this property and the concatBindings method be combined as an es5 get or computed?
    classNameBindings: [],

    /**
      Combine all of bulmas grid specific classes with responsive helper classes
       @method concatBindings
      @private
    */
    concatBindings: function concatBindings() {
      set(this, 'classNameBindings', ['content'].concat(_constants._gridBindings, _constants._responsiveHelpers, _constants._helpers));
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.concatBindings();
    }
  });
});