define('ember-bulma/components/bulma-textarea', ['exports', 'ember-bulma/templates/components/bulma-textarea'], function (exports, _bulmaTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaTextarea.default,
    tagName: 'textarea',
    classNames: ['textarea'],
    // Bindings are not comprehensive. More complex implementations should use a native element with classes applied
    attributeBindings: ['isDisabled: disabled', 'autofocus', 'cols', 'disabled', 'form', 'name', 'placeholder', 'readonly', 'required', 'rows', 'wrap', 'onkeydown', 'onkeyup', 'onkeypress', 'oninput']
  });
});