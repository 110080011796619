define('ember-bulma/components/bulma-table', ['exports', 'ember-bulma/templates/components/bulma-button', 'ember-bulma/constants'], function (exports, _bulmaButton, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaButton.default,
    tagName: 'table',
    classNames: ['table'],
    classNameBindings: ['isBordered:is-bordered', 'bordered:is-bordered', 'isStriped:is-striped', 'striped:is-striped', 'isNarrow:is-narrow', 'narrow:is-narrow'].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});