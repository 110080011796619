define('ember-bulma/components/bulma-section', ['exports', 'ember-bulma/templates/components/bulma-section', 'ember-bulma/constants'], function (exports, _bulmaSection, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaSection.default,
    tagName: 'section',
    classNames: ['section'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers),
    /**
      Signal if content should be nested in a container (div.container)
       // TODO depecrate this
       @property withContainer
      @returns Boolean
      @public
    */
    withContainer: true
  });
});