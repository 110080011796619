define('ember-bulma/components/bulma-modal-background', ['exports', 'ember-bulma/templates/components/bulma-modal-background'], function (exports, _bulmaModalBackground) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaModalBackground.default,
    classNames: ['modal-background']
  });
});