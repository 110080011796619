define('ember-bulma/components/bulma-message-body', ['exports', 'ember-bulma/templates/components/bulma-message-body', 'ember-bulma/constants'], function (exports, _bulmaMessageBody, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaMessageBody.default,
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});