define('ember-bulma/components/bulma-hero-footer', ['exports', 'ember-bulma/templates/components/bulma-hero-footer', 'ember-bulma/constants'], function (exports, _bulmaHeroFooter, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaHeroFooter.default,
    classNames: ['hero-foot'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers),
    /**
      Signal if content should be nested in a container (div.container)
       @property useContainer
      @returns Boolean
      @default true
      @public
    */
    useContainer: true,

    /**
      Adds .is-fluid to nested container
       @property fluidContainer
      @returns Boolean
      @default false
      @public
    */
    fluidContainer: false
  });
});