define('ember-bulma/components/bulma-panel-tabs', ['exports', 'ember-bulma/templates/components/bulma-panel-tabs', 'ember-bulma/constants'], function (exports, _bulmaPanelTabs, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaPanelTabs.default,
    classNames: ['panel-tabs'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});