define("ember-intl/-private/utils/flatten", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-intl/-private/utils/empty-object"], function (_exports, _typeof2, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flatten;
  var hasOwnProperty = Object.prototype.hasOwnProperty;

  /**
   * @private
   * @hide
   */
  function flatten(src) {
    var result = new _emptyObject.default();

    for (var key in src) {
      if (!hasOwnProperty.call(src, key)) {
        continue;
      }

      var value = src[key];

      if ((0, _typeof2.default)(value) === 'object' && value) {
        var hash = flatten(value);

        for (var suffix in hash) {
          result["".concat(key, ".").concat(suffix)] = hash[suffix];
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  }
});