define('ember-bulma/components/bulma-progress', ['exports', 'ember-bulma/templates/components/bulma-progress', 'ember-bulma/constants'], function (exports, _bulmaProgress, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaProgress.default,
    tagName: 'progress',
    classNames: ['progress'],
    attributeBindings: ['value', 'max'],
    label: null,
    classNameBindings: [].concat(_constants._helpers, _constants._colorBindings, _constants._responsiveHelpers)
  });
});