define('ember-bulma/components/bulma-modal-close', ['exports', 'ember-bulma/templates/components/bulma-modal-close'], function (exports, _bulmaModalClose) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    layout: _bulmaModalClose.default,
    tagName: 'button',
    classNames: 'modal-close',

    /**
      Perform closure action provided to close modal
       @method close
      @private
    */
    close: function close() {
      var action = get(this, 'onclose');
      if (action) {
        action(this);
      }
    },


    /**
      Optional support for mouseDown in addition to click
       @method mouseDown
      @public
    */
    mouseDown: function mouseDown() {
      this.close();
    },


    /**
      Support for click
       @method click
      @public
    */
    click: function click() {
      this.close();
    },


    /**
      Support for closing modal using the escape key
       @method keyDown
      @private
      */
    keyDown: function keyDown(e) {
      // on escape key
      if (e.which === 27) {
        this.close();
      }
    }
  });
});