define('ember-bulma/components/bulma-nav', ['exports', 'ember-bulma/templates/components/bulma-nav', 'ember-bulma/constants'], function (exports, _bulmaNav, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaNav.default,
    tagName: 'nav',
    classNames: ['nav'],
    classNameBindings: ['hasShadow:has-shadow'].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});