define('ember-bulma/components/bulma-select', ['exports', 'ember-bulma/templates/components/bulma-select', 'ember-decorators/object', 'ember-bulma/utils'], function (exports, _bulmaSelect, _object, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      emberArray = Ember.A,
      get = Ember.get;
  exports.default = Component.extend((_dec = (0, _object.computed)('classNames'), (_obj = {
    layout: _bulmaSelect.default,
    tagName: '',
    classNames: ['bulma-select'],
    /**
      If used inline, consumer will provide an array of options to the select
       @property options
      @returns Array
      @public
    */
    options: emberArray([]),

    _classes: function _classes(classNames) {
      return classNames.toString().replace(/,/g, ' ');
    },

    actions: {
      /**
        Handle change event, return value as string, event object, and instance of component for mutablity
         @method handleMousedown
        @returns String, Object, Class
        @public
      */
      handleChange: function handleChange(e) {
        var dropdown = e.target;
        var val = dropdown.options[dropdown.selectedIndex].value;
        var onchange = this.get('onchange');

        if (onchange) {
          onchange(val, e, this);
        } else {
          return [val, e, this];
        }
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      // Make user aware of deprecations for this Component
      (0, _utils.deprecations)([{ name: 'control', value: get(this, 'control') }]);
    }
  }, (_applyDecoratedDescriptor(_obj, '_classes', [_dec], Object.getOwnPropertyDescriptor(_obj, '_classes'), _obj)), _obj)));
});