define('ember-bulma/components/bulma-header', ['exports', 'ember-bulma/templates/components/bulma-header', 'ember-bulma/constants'], function (exports, _bulmaHeader, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _bulmaHeader.default,
    tagName: 'header',
    classNames: ['header'],
    classNameBindings: [].concat(_constants._helpers, _constants._responsiveHelpers)
  });
});