define('ember-bulma/services/grid-bindings', ['exports', 'ember-bulma/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      emberArray = Ember.A;
  exports.default = Service.extend({
    /**
      All the bindings
       @property all
      @returns Array
      @public
    */
    all: null,

    /**
      Assemble class name bindings from all the possibilities
       @method _generateAllBindings
      @private
    */
    _generateAllBindings: function _generateAllBindings() {
      var possibilities = emberArray(['three-quarters', 'two-thirds', 'half', 'one-third', 'one-quarter', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']);
      var modifiers = emberArray(['offset', 'narrow', 'desktop', 'mobile', 'tablet']);
      var bindings = emberArray([]);

      possibilities.forEach(function (item) {
        // Add all the possibilities
        bindings.pushObject('is' + (0, _utils.camelCase)(item) + ':is-' + item);

        // Add all the modifiers to all the possibilities
        modifiers.forEach(function (modifier) {
          // offset & narrow should be prepended since 0.2.6
          // TODO: scale this better
          if (modifier === 'offset' || modifier === 'narrow') {
            bindings.pushObject('is' + (0, _utils.camelCase)(modifier) + (0, _utils.camelCase)(item) + ':is-' + modifier + '-' + item);
          } else {
            // append modifier
            bindings.pushObject('is' + (0, _utils.camelCase)(item) + (0, _utils.camelCase)(modifier) + ':is-' + item + '-' + modifier);
          }
        });
      });
      this.set('all', bindings);
    },
    init: function init() {
      /*
        REVIEW Why do this assemblage as a process when values are always static?
        Instead just use static result as a constant, and keep this code if nomenclature changes, but never execute
      */
      // this._generateAllBindings();
    }
  });
});